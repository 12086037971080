import ActionUtility from "../ActionUtility";

export default class InvestigationAction {

  static GET_INVESTIGATION_LIST = "InvestigationAction.GET_INVESTIGATION_LIST";
  //static SET_INVESTIGATION_LIST = "InvestigationAction.SET_INVESTIGATION_LIST";
  static GET_CASES_LIST = "InvestigationAction.GET_CASES_LIST";

  static SET_HIERARCHY = "InvestigationAction.SET_HIERARCHY";
  static CLEAR_HIERARCHY = "InvestigationAction.CLEAR_HIERARCHY";

  static SET_CHOSEN_MODE = "InvestigationAction.SET_CHOSEN_MODE";
  static SET_CHOSEN_ITEM = "InvestigationAction.SET_CHOSEN_ITEM";
  static SET_CHOSEN_LIST = "InvestigationAction.SET_CHOSEN_LIST";
  static SET_TREE_KEY = "InvestigationAction.SET_TREE_KEY";


  static SET_INVESTIGATION_LOADING = "InvestigationAction.SET_INVESTIGATION_LOADING";

  static CREATE_INVESTIGATION = "InvestigationAction.CREATE_INVESTIGATION";
  static UPDATE_INVESTIGATION = "InvestigationAction.UPDATE_INVESTIGATION";
  static UPDATE_INVESTIGATION_SUCCESS = "InvestigationAction.UPDATE_INVESTIGATION_SUCCESS";
  static CREATE_INVESTIGATION_SUCCESS = "InvestigationAction.CREATE_INVESTIGATION_SUCCESS";
  static DELETE_INVESTIGATION = "InvestigationAction.DELETE_INVESTIGATION";
  static DELETE_INVESTIGATION_SUCCESS = "InvestigationAction.DELETE_INVESTIGATION_SUCCESS";

  static CREATE_CASE = "InvestigationAction.CREATE_CASE";
  static UPDATE_CASE = "InvestigationAction.UPDATE_CASE";
  static CREATE_CASE_SUCCESS = "InvestigationAction.CREATE_CASE_SUCCESS";
  static UPDATE_CASE_SUCCESS = "InvestigationAction.UPDATE_CASE_SUCCESS";
  static DELETE_CASE = "InvestigationAction.DELETE_CASE";
  static DELETE_CASE_SUCCESS = "InvestigationAction.DELETE_CASE_SUCCESS";

  static GET_TARGETS_LIST = "InvestigationAction.GET_TARGETS_LIST";
  static SET_TARGETS_LOADING = "InvestigationAction.SET_TARGETS_LOADING";

  static CREATE_PROFILER_TARGET = "InvestigationAction.CREATE_PROFILER_TARGET";
  static CREATE_PROFILER_TARGET_SUCCESS = "InvestigationAction.CREATE_PROFILER_TARGET_SUCCESS";
  static DELETE_TARGET = "InvestigationAction.DELETE_TARGET";
  static DELETE_TARGET_SUCCESS = "InvestigationAction.DELETE_TARGET_SUCCESS";

  static CREATE_WEBINT_TARGET = "InvestigationAction.CREATE_WEBINT_TARGET";
  static CREATE_WEBINT_TARGET_SUCCESS = "InvestigationAction.CREATE_WEBINT_TARGET_SUCCESS";


  static GET_LINK_ANALYSIS = "InvestigationAction.GET_LINK_ANALYSIS";

  static SET_FILTERS = "InvestigationAction.SET_FILTERS";


  static getInvestigationList(pathId = null) {
    return ActionUtility.createAction(InvestigationAction.GET_INVESTIGATION_LIST, {pathId});
  }

  /*static setInvestigationList(list) {
    return ActionUtility.createAction(InvestigationAction.SET_INVESTIGATION_LIST, list);
  }*/

  static getCasesList(investigationId, pathId = null, user, callback) {
    return ActionUtility.createAction(InvestigationAction.GET_CASES_LIST, {investigationId, pathId, user, callback});
  }

  static setHierarchy(hierarchy) {
    return ActionUtility.createAction(InvestigationAction.SET_HIERARCHY, hierarchy);
  }
  static clearHierarchy() {
    return ActionUtility.createAction(InvestigationAction.CLEAR_HIERARCHY);
  }

  static setChosenMode(mode) {
    return ActionUtility.createAction(InvestigationAction.SET_CHOSEN_MODE, mode);
  }

  static setChosenItem(item) {
    return ActionUtility.createAction(InvestigationAction.SET_CHOSEN_ITEM, item);
  }

  static setChosenList(list) {
    return ActionUtility.createAction(InvestigationAction.SET_CHOSEN_LIST, list);
  }

  static setTreeKey(key) {
    return ActionUtility.createAction(InvestigationAction.SET_TREE_KEY, key);
  }

  static setInvestigationLoading(data) {
    return ActionUtility.createAction(InvestigationAction.SET_INVESTIGATION_LOADING, data);
  }

  static createInvestigation(name, callback) {
    return ActionUtility.createAction(InvestigationAction.CREATE_INVESTIGATION, {name, callback});
  }

  static updateInvestigation(id, name, callback) {
    return ActionUtility.createAction(InvestigationAction.UPDATE_INVESTIGATION, {id, name, callback});
  }

  static updateInvestigationSuccess(investigation) {
    return ActionUtility.createAction(InvestigationAction.UPDATE_INVESTIGATION_SUCCESS, investigation);
  }

  static createInvestigationSuccess(investigation) {
    return ActionUtility.createAction(InvestigationAction.CREATE_INVESTIGATION_SUCCESS, investigation);
  }

  static deleteInvestigation(id) {
    return ActionUtility.createAction(InvestigationAction.DELETE_INVESTIGATION, id);
  }

  static deleteInvestigationSuccess(id) {
    return ActionUtility.createAction(InvestigationAction.DELETE_INVESTIGATION_SUCCESS, id);
  }

  static createCase(name, invId, type, callback) {
    return ActionUtility.createAction(InvestigationAction.CREATE_CASE, {name, invId, type, callback});
  }

  static updateCase(id, name, callback) {
    return ActionUtility.createAction(InvestigationAction.UPDATE_CASE, {id, name, callback});
  }

  static updateCaseSuccess(caze) {
    return ActionUtility.createAction(InvestigationAction.UPDATE_CASE_SUCCESS, caze);
  }

  static createCaseSuccess(caseObj) {
    return ActionUtility.createAction(InvestigationAction.CREATE_CASE_SUCCESS, caseObj);
  }

  static deleteCase(id) {
    return ActionUtility.createAction(InvestigationAction.DELETE_CASE, id);
  }

  static deleteCaseSuccess(id) {
    return ActionUtility.createAction(InvestigationAction.DELETE_CASE_SUCCESS, id);
  }

  static getTargetsList(caseId, type = 'PROFILER', paginateObj) {
    return ActionUtility.createAction(InvestigationAction.GET_TARGETS_LIST, {caseId, type, paginateObj});
  }

  static setTargetsLoading(loading) {
    return ActionUtility.createAction(InvestigationAction.SET_TARGETS_LOADING, loading);
  }

  //PROFILER
  static createProfilerTarget(caseId, formData, callback) {
    return ActionUtility.createAction(InvestigationAction.CREATE_PROFILER_TARGET, {caseId, formData, callback});
  }

  static createProfilerTargetSuccess(targetObj) {
    return ActionUtility.createAction(InvestigationAction.CREATE_PROFILER_TARGET_SUCCESS, targetObj);
  }

  //WEBINT
  static createWebintTarget(caseId, formData, callback) {
    return ActionUtility.createAction(InvestigationAction.CREATE_WEBINT_TARGET, {caseId, formData, callback});
  }

  static createWebintTargetSuccess(targetObj) {
    return ActionUtility.createAction(InvestigationAction.CREATE_WEBINT_TARGET_SUCCESS, targetObj);
  }


  static deleteTarget(id, type = 'PROFILER') {
    return ActionUtility.createAction(InvestigationAction.DELETE_TARGET, {id, type});
  }

  static deleteTargetSuccess(id) {
    return ActionUtility.createAction(InvestigationAction.DELETE_TARGET_SUCCESS, id);
  }

  static getLinkAnalysis(callback) {
    return ActionUtility.createAction(InvestigationAction.GET_LINK_ANALYSIS, callback);
  }

  static setFilters(filters) {
    return ActionUtility.createAction(InvestigationAction.SET_FILTERS, filters);
  }

}