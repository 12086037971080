import {globals} from './globals';
import locales from "./locales";
import {EXT_TITLE} from "./consts";

const points = {
  api_auth: globals.api_url + "/auth",
  api_image: globals.api_url + "/image",
  api_mm_image: globals.api_mm_url + "/image",
  api_user: globals.api_url + "/user",
  api_investigation: globals.api_url + "/inv",
  api_case: globals.api_url + "/case",
  api_profile: globals.api_url + "/profile",
  api_webint: globals.api_url + "/webint",
  api_websocket: globals.api_base_path + "/mm-websocket",
};

const env = window._env_ || process.env;

const shared = {
  apaLink: env?.REACT_APP_APA_LINK || "http://localhost:3333/app/apa/v1",
  roles: {
    admin_roles: ["ADMIN"],
    user_roles: ["USER"],
  },
  usersPageSize: env?.REACT_APP_USERS_PAGE_SIZE ? env?.REACT_APP_USERS_PAGE_SIZE * 1 : 10,
  targetListPageSize: env?.REACT_APP_TARGET_LIST_PAGE_SIZE ? env?.REACT_APP_TARGET_LIST_PAGE_SIZE * 1 : 15,
  avatarsListPageSize: env?.REACT_APP_AVATARS_LIST_PAGE_SIZE ? env?.REACT_APP_AVATARS_LIST_PAGE_SIZE * 1 : 200,

  maxImportPhonesFileSize: env.REACT_APP_MAX_IMPORT_PHONES_FILE_SIZE
    ? window.Function('"use strict";return (' + env.REACT_APP_MAX_IMPORT_PHONES_FILE_SIZE + ')')()
    : 10 * 1024 * 1024,
  maxImportPhonesNumber: env?.REACT_APP_MAX_IMPORT_PHONES_NUMBER || 50,
  maxUploadFileSizeMB: env?.REACT_APP_MAX_UPLOAD_FILE_SIZE_MB || 10,
  maxUploadFileNumber: env?.REACT_APP_MAX_UPLOAD_FILE_NUMBER || 3,
  maxAlertCriteria: env?.REACT_APP_MAX_ALERT_CRITERIA || 3,
  profilerShowIMSI: env?.REACT_APP_PROFILER_SHOW_IMSI === 'true',
  googleMapsAPIKey: env?.REACT_APP_GOOGLE_MAPS_KEY || 'AIzaSyB3yrXjiHMUAxWYgOnpubncfPasXCdmO-E',

  moduleAvatars: env?.REACT_APP_MODULE_AVATARS === 'true',
  moduleAlerts: env?.REACT_APP_MODULE_ALERTS === 'true',
  moduleTopPosts: env?.REACT_APP_MODULE_TOP_POSTS === 'true',

  headerNewTab: env?.REACT_APP_HEADER_NEW_TAB === 'true',
  headerLanguages: env?.REACT_APP_HEADER_LANGUAGES === 'true',
  headerLogout: env?.REACT_APP_HEADER_LOGOUT === 'true',
  headerLogo: env?.REACT_APP_HEADER_LOGO || 'logo',
  headerUsername: env?.REACT_APP_HEADER_USERNAME || null,
  headerUserManual: env?.REACT_APP_HEADER_USERMANUAL || null,

  profilerUseAvatars: env?.REACT_APP_PROFILER_USE_AVATARS === 'true',
  profilerExcludeAvatars: (env?.REACT_APP_PROFILER_EXCLUDE_AVATARS || '').split(','),
  profilerWebintFbNeedAvatars: env?.REACT_APP_PROFILER_WEBINT_FB_NEED_AVATARS === 'true',
  profilerSearchSingleOption: env?.REACT_APP_PROFILER_SEARCH_SINGLE_OPTION === 'true',
  profilerAddPhone: env?.REACT_APP_PROFILER_ADD_PHONE === 'true',
  profilerAddSourceFromNetworks: env?.REACT_APP_PROFILER_ADD_SOURCE_FROM_NETWORKS === 'true',
  profilerUseHydra: env?.REACT_APP_PROFILER_USE_HYDRA === 'true',
  profilerUpdateSources: env?.REACT_APP_PROFILER_UPDATE_SOURCES === 'true',
  profilerFetchMoreFriends: env?.REACT_APP_PROFILER_FETCH_MORE_FRIENDS === 'true',
  profilerFetchMorePosts: env?.REACT_APP_PROFILER_FETCH_MORE_POSTS === 'true',
  profilerPostAIAnalysis: env?.REACT_APP_PROFILER_POST_AI_ANALYSIS === 'true',
  profilerShowVerifiedSign: env?.REACT_APP_PROFILER_SHOW_VERIFIED_SIGN === 'true',
  profilerPsychoProfile: env?.REACT_APP_PROFILER_PSYCHO_PROFILE === 'true',
  profilerAutoRefresh: env?.REACT_APP_PROFILER_AUTOREFRESH === 'true',
  profilerAutoRefreshInterval: env?.REACT_APP_PROFILER_AUTOREFRESH_INTERVAL_MS || 30000,
  profilerAutoRefreshMaxAttempts: env?.REACT_APP_PROFILER_AUTOREFRESH_MAX_ATTEMPTS || 10,
  profilerDotsMode: env?.REACT_APP_PROFILER_DOTS_MODE === 'true',
  profilerAiAssistant: env?.REACT_APP_PROFILER_AI_ASSISTANT === 'true',
  profilerAiContactInfo: env?.REACT_APP_PROFILER_AI_CONTACT_INFO === 'true',

  avatarsUseDefault: env?.REACT_APP_AVATARS_USE_DEFAULT === 'true',
  avatarsAiPostsGeneration: env?.REACT_APP_AVATARS_AI_POST_GENERATION === 'true',
  availableAlerts: (env?.REACT_APP_ALERTS || '').split(','),
  webintTelegramMorePhotos: env?.REACT_APP_WEBINT_TELEGRAM_MORE_PHOTOS === 'true',
  webintGetcontactMoreTags: env?.REACT_APP_WEBINT_GETCONTACT_MORE_TAGS === 'true',
  webintPostAIAnalysis: env?.REACT_APP_WEBINT_POST_AI_ANALYSIS === 'true',
  webintPostsTwitterSearch: env?.REACT_APP_WEBINT_POSTS_TWITTER_SEARCH === 'true',
  webintTaskTypesAvailable: env?.REACT_APP_WEBINT_TASK_TYPES_AVAILABLE || 'groups,keywords,posts,keywords_groups',
  webintTaskTypesAvatars: (env?.REACT_APP_WEBINT_TASK_TYPES_AVATARS || '').split(',')?.filter(a => a),
  webintTaskKeywordsSn: (env?.REACT_APP_WEBINT_TASK_KEYWORDS_SN || '').split(',')?.filter(a => a),
  webintTaskCollectReactions: env?.REACT_APP_WEBINT_TASK_COLLECT_REACTIONS === 'true',
  webintTrendsSource: env?.REACT_APP_WEBINT_TRENDS_SOURCE || 'google',
  webintMonitoringMinIntervalHrs: env?.REACT_APP_WEBINT_MONITORING_MIN_INTERVAL_HRS || 3,
  webintAiAssistant: env?.REACT_APP_WEBINT_AI_ASSISTANT === 'true',

  browseDeleteItems: env?.REACT_APP_BROWSE_DELETE_ITEMS === 'true',
  browseRenameItems: env?.REACT_APP_BROWSE_RENAME_ITEMS === 'true',
  investigationShowCreator: env?.REACT_APP_INVESTIGATION_SHOW_CREATOR === 'true',

  userDailyQuota: env?.REACT_APP_USER_DAILY_QUOTA === 'true',
  userRename: env?.REACT_APP_USER_RENAME === 'true',

  linkAnalysisType: env?.REACT_APP_LINK_ANALYSIS_TYPE || 'regraph',
  appTitle: env?.REACT_APP_TITLE || EXT_TITLE,
  theme: env?.REACT_APP_THEME || 'default',

  //irbisPackagesPage: document.referrer ? `${document.referrer.replace(/\/$/, '')}${env?.REACT_APP_IRBIS_PACKAGES}` : '',
  irbisPackagesPage: env?.REACT_APP_IRBIS_PACKAGES || '',
  irbisProMode: env?.REACT_APP_IRBIS_PRO_MODE === 'true',
  irbisHeaderQuota: env?.REACT_APP_IRBIS_PRO_MODE === 'true' && env?.REACT_APP_IRBIS_HEADER_QUOTA === 'true',

  dotsMaxNodes: env?.REACT_APP_DOTS_MAX_NODES || 5,
};
const AppConfig = { ...globals, ...points, ...shared, ...locales };

export default AppConfig;